import * as React from "react";

import { DashboardSkeleton } from "@brainfinance/icash-component-library";
import { navigate } from "gatsby";

import { useLocation } from "@reach/router";
import { logBranchEvent } from "@helpers/branch";
import { setLocalStorageUserSession } from "@helpers/auth";
import { mariaFetch } from "@helpers/maria-fetch";

const RedirectionPage = () => {
  const location = useLocation();

  const onStartRedirection = async (otp: string, route: string) => {
    const response = await mariaFetch("/user/login", {
      method: "POST",
      headers: {
        "Application-ID": "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ otp: otp }),
    });

    const data = (await response.json()) as {
      error?: { code: number; message: string };
      result?: { user: { email: string }; session: { token: string } };
    };

    if (data.error) {
      console.warn(data.error.message);
      navigate("/");
      return;
    }

    if (data.result) {
      logBranchEvent("auth_login_submitted", {
        userEmail: data.result.user.email,
      });
      setLocalStorageUserSession(data.result.session.token);
      navigate(`/${route}`);
      return;
    }
    navigate("/");
  };

  React.useEffect(() => {
    const search = location.search;
    const otp = new URLSearchParams(search).get("otp");
    const route = new URLSearchParams(search).get("route");

    if (otp && route) {
      onStartRedirection(otp, route);
    }
  }, [location]);

  return (
    <div className="m-[20px]">
      <DashboardSkeleton />
    </div>
  );
};

export default RedirectionPage;
